// extracted by mini-css-extract-plugin
export var Actions = "Automations-module--Actions--K-GQK";
export var Anywhere = "Automations-module--Anywhere--ET8Ga";
export var Arrows = "Automations-module--Arrows--1FngE";
export var Button = "Automations-module--Button--7G4Kr";
export var Container = "Automations-module--Container--IeqwI";
export var Content = "Automations-module--Content--6RSHY";
export var CurvyLine = "Automations-module--CurvyLine--9L2dF";
export var DataUsageOptions = "Automations-module--DataUsageOptions--TnTyh";
export var Headline = "Automations-module--Headline--B3eLp";
export var Hero = "Automations-module--Hero--ySINw";
export var Highlight = "Automations-module--Highlight--suELA";
export var LoadData = "Automations-module--LoadData--XTpwj";
export var MachineProcesses = "Automations-module--MachineProcesses--hKdSM";
export var Machines = "Automations-module--Machines--UHYtQ";
export var Option = "Automations-module--Option--i44tX";
export var People = "Automations-module--People--wxVDA";
export var PeopleProcesses = "Automations-module--PeopleProcesses--2difB";
export var Server = "Automations-module--Server--yv4gL";
export var ShortLine = "Automations-module--ShortLine--BID-A";